import axios from "axios";
import { BASE_URL } from "../constants/environnement.types";
import { hideLoading, showLoading, showToast } from "../constants/utils";

export const logout = (displayToast = true) => {
  if (displayToast) {
    showToast("Session expirée veuillez vous reconnecter");
  }
  sessionStorage.removeItem("auth_token");
  sessionStorage.removeItem("auth");
  sessionStorage.removeItem("userInfos");
  window.location.href = "/";
};

export const getAction = async (url, headers) => {
  try {
    showLoading();
    const response = await axios.get(
      String(url).startsWith("http") ? url : `${BASE_URL}/${url}`,
      {
        headers: {
          authorization: `Bearer ${sessionStorage.getItem("auth_token")}`,
          ...headers,
        },
      }
    );
    hideLoading();
    return response.data;
  } catch (error) {
    hideLoading();
    console.log(error);
    if (error?.response?.status === 401) {
      logout();
    }
    return { success: false };
  }
};

export const postActions = async (url, payload) => {
  try {
    showLoading();
    const response = await axios.post(
      `${BASE_URL}/${url}`,
      { ...payload },
      {
        headers: {
          authorization: `Bearer ${sessionStorage.getItem("auth_token")}`,
        },
      }
    );
    hideLoading();
    return response;
  } catch (error) {
    hideLoading();
    console.log(error);
    if (error?.response?.status === 401) {
      logout();
    }
    showToast(error?.response?.data?.message);
    return { success: false };
  }
};

export const uploadImageActions = async (url, payload) => {
  try {
    showLoading();
    const response = await axios.post(`${BASE_URL}/${url}`, payload, {
      headers: {
        "Content-Type": "multipart/form-data",
        authorization: `Bearer ${sessionStorage.getItem("auth_token")}`,
      },
    });
    hideLoading();
    return response;
  } catch (error) {
    hideLoading();
    console.log(error);
    if (error?.response?.status === 401) {
      logout();
    }
    return { success: false };
  }
};

export const putActions = async (url, payload) => {
  try {
    showLoading();
    const response = await axios.put(
      `${BASE_URL}/${url}/${payload?.id}`,
      { ...payload },
      {
        headers: {
          authorization: `Bearer ${sessionStorage.getItem("auth_token")}`,
        },
      }
    );
    hideLoading();
    return response;
  } catch (error) {
    hideLoading();
    console.log(error);
    if (error?.response?.status === 401) {
      logout();
    }
    return { success: false };
  }
};

export const deleteActions = async (url, id) => {
  try {
    showLoading();
    const response = await axios.delete(`${BASE_URL}/${url}/${id}`, {
      headers: {
        authorization: `Bearer ${sessionStorage.getItem("auth_token")}`,
      },
    });
    hideLoading();
    return response;
  } catch (error) {
    hideLoading();
    console.log(error);
    if (error?.response?.status === 401) {
      logout();
    }
    showToast(error?.response?.data?.message);
    return { success: false };
  }
};

export const transformDataForTableComponent = (settings, data) => {
  const keysToRetrieve = [];
  for (let index = 0; index < settings.length; index++) {
    const element = settings[index];
    keysToRetrieve.push(element?.key);
  }
  const temp = data;
  for (let index = 0; index < temp.length; index++) {
    const element = temp[index];
    element.data = [];
    for (let subIndex = 0; subIndex < keysToRetrieve.length; subIndex++) {
      const key = keysToRetrieve?.[subIndex];
      const keyNameSpacesSplitted = String(key).split(".");
      if (keyNameSpacesSplitted.length === 1) {
        element.data.push(element[`${key}`]);
        continue;
      }
      if (keyNameSpacesSplitted.length === 4) {
        if (
          element?.[`${keyNameSpacesSplitted[0]}`]?.[
            `${keyNameSpacesSplitted[1]}`
          ]?.[`${keyNameSpacesSplitted[2]}`]
        ) {
          element?.data?.push(
            element?.[`${keyNameSpacesSplitted[0]}`]?.[
              `${keyNameSpacesSplitted[1]}`
            ]?.[`${keyNameSpacesSplitted[2]}`]?.[`${keyNameSpacesSplitted[3]}`]
          );
        } else {
          element?.data?.push("");
        }
      } else {
        if (keyNameSpacesSplitted.length === 3) {
          if (
            element?.[`${keyNameSpacesSplitted[0]}`]?.[
              `${keyNameSpacesSplitted[1]}`
            ]
          ) {
            element?.data?.push(
              element?.[`${keyNameSpacesSplitted[0]}`]?.[
                `${keyNameSpacesSplitted[1]}`
              ]?.[`${keyNameSpacesSplitted[2]}`]
            );
          } else {
            element?.data?.push("");
          }
        } else {
          if (keyNameSpacesSplitted.length === 2) {
            if (element?.[`${keyNameSpacesSplitted[0]}`]) {
              element?.data?.push(
                element?.[`${keyNameSpacesSplitted[0]}`][
                  `${keyNameSpacesSplitted[1]}`
                ]
              );
            } else {
              element?.data?.push("");
            }
          }
        }
      }
    }
  }
  return temp;
};

export const transformSingleDataForTableComponent = (settings, data) => {
  const keysToRetrieve = [];
  for (let index = 0; index < settings.length; index++) {
    const element = settings[index];
    keysToRetrieve.push(element?.key);
  }
  const temp = data;
  temp.data = [];
  for (let subIndex = 0; subIndex < keysToRetrieve.length; subIndex++) {
    const key = keysToRetrieve[subIndex];
    temp.data.push(temp[`${key}`]);
  }
  return temp;
};

export const updateComponentKey = (currentKey) => {
  return `${currentKey.split("-")[0]}-${Number(currentKey.split("-")[1]) + 1}`;
};
